var UserRoles = (function () {
   
    var Researcher = "Researcher";
    var MedicalProfessional = "MedicalProfessional";
    var MedicalTeamAdmin = "MedicalTeamAdmin";

    return{
        Researcher: Researcher,
        MedicalProfessional: MedicalProfessional,
        MedicalTeamAdmin: MedicalTeamAdmin
    }
  
  })();
  
  export default UserRoles;